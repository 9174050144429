<template>
  <nav class="left-nav" @click=toggle :class="{expand: expand}">
    <router-link to="/settings"><i class="fa fa-user"></i> Profile</router-link>
    <router-link to="/orders"><i class="fa fa-file-text"></i> Orders</router-link>
    <!-- <router-link to="/transactions" class="transactions">Transactions</router-link> -->
    <router-link to="/addresses"><i class="fa fa-address-book-o"></i> Addresses</router-link>
    <!-- <span class="separator-line"></span> -->
    <!-- <router-link to="/logout" class="logout">Logout</router-link> -->
  </nav>
</template>
<script>
export default {
  name: "LeftMenu",
  data() {  
    return {
      expand: false
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand;
    }
  }
}
</script>
<style lang="scss">
.left-nav a.router-link-active {
  opacity: 1;
  background-image: linear-gradient(100deg, #1985A1 0%, #024455 100%);
  color: #fff;
  border-radius: 10px !important;
  padding-left: 10px !important;
}
.left-nav {
  padding: 18px 20px !important;
  border-radius: 10px !important;
  background-color: #F4F4F4  !important;
  box-shadow: 4px 5px 20px #81818142;
  // height: 65vh;
  min-height: 350px;
}
.left-nav a {
  padding-left: 9px !important;
    opacity: 1 !important;
  color: #1a1927d1;
}
.left-nav a i{
  padding-right: 10px;
}
@media screen and (max-width: 668px) {
  .left-nav {
    display: none !important;
  }
}
</style>